@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* 
  *  CSS TABLE OF CONTENTS
  *   
  *  Globals
  *  Fonts
  *  Menus
  *  Buttons
  *  Form Inputs
  *  Header
  *  Body
  *    Cards
  *    Sliders
  *    Imagery
  *    Notifications
  *  Footer
  */

  /*** Globals ***/
  code {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
      Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  #faq code {
    font-size: 0.875rem;
  }

  #faq pre {
    margin-top: 0.5rem;
  }

  /*** Fonts ***/

  /*** Menus ***/
  .mobile-menu {
    margin-left: 0.6rem;
  }

  /*** Buttons ***/

  .btn {
    @apply w-full py-4 px-3 font-medium text-sm text-white rounded-2xl shadow-md focus:outline-none uppercase;
    background: #623c68;
  }

  .btn:hover {
    background: #3f2742;
  }

  .btn-secondary {
    @apply w-full py-4 px-3 font-medium text-sm text-white rounded-2xl shadow-md focus:outline-none uppercase;
    background: #33cccc;
  }

  .btn-secondary:hover {
    background: #3ecccb;
  }

  .btn-sm {
    @apply w-full p-2 text-sm text-white rounded-xl shadow-md focus:outline-none;
    background: #623c68;
  }

  .btn-sm:hover {
    background: #3f2742;
  }

  .btn-sm-secondary {
    @apply w-full p-2 text-sm text-white rounded-xl shadow-md focus:outline-none;
    background: #3ecccb;
  }

  .btn-sm-secondary:hover {
    background: #3ecccb;
  }

  .btn-cancel {
    @apply w-full p-4 bg-white font-medium text-gray-400 rounded-2xl focus:outline-none hover:bg-gray-50 border border-primaryLight;
    border-color: #d3b9d6;
  }

  .btn-coupon {
    @apply w-full p-2 bg-primaryLight font-medium text-white rounded-2xl focus-within:outline-none hover:bg-gray-50 hover:text-primary border border-primaryLight;
  }

  .btn-disabled {
    @apply w-full py-4 px-4 bg-gray-200 font-medium text-white rounded-2xl shadow-md focus:outline-none uppercase cursor-not-allowed;
  }

  .btn-nevermind {
    @apply w-full p-3 bg-gray-200 text-xs font-medium rounded-2xl shadow-md focus:outline-none hover:bg-gray-300;
  }

  .btn-deny {
    @apply w-full p-3 text-xs text-white font-medium rounded-2xl shadow-md focus:outline-none;
    background: #623c68;
  }

  .btn-deny:hover {
    background: #3f2742;
  }

  .btn-file {
    @apply w-full p-0 font-medium text-white rounded-2xl shadow-md focus:outline-none cursor-pointer;
  }

  .btn-file:hover {
    background: #3f2742;
  }

  .btn-file::before {
    content: "";
    background-image: url("https://res.cloudinary.com/that1thing/image/upload/v1640002737/image_icon_55eaeaef72.svg");
    position: absolute;
    width: 30px;
    height: 30px;
    margin-left: calc(50% - 1rem);
    margin-top: 0.5rem;
    z-index: 0;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    .btn-sm,
    .btn-sm-secondary {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  /*** 4.0 - Form Inputs ***/

  .input-sm {
    @apply w-full p-1.5 border border-gray-300 rounded-3xl focus:outline-none focus:ring-2 shadow-sm  focus:border-transparent mb-5 placeholder-gray-300 text-sm;
  }

  .input-sm:focus {
    outline-color: #d3b9d6;
  }

  .input-md {
    @apply w-full p-2.5 border border-gray-300 rounded-3xl focus:outline-none focus:ring-2 shadow-sm  focus:border-transparent mb-5 placeholder-gray-300 text-sm mt-2;
  }

  .input-md:focus {
    outline-color: #d3b9d6;
  }

  .input-lg {
    @apply w-full p-3 border border-gray-300 rounded-3xl focus:outline-none focus:ring-2 shadow-sm focus:border-transparent mb-5 placeholder-gray-300 mt-2;
  }

  .input-lg:focus {
    outline-color: #d3b9d6;
  }

  .input-checkbox-disabled {
    @apply bg-gray-200 focus:ring-gray-200 cursor-not-allowed border-none;
  }

  .input-lg-disabled {
    @apply w-full p-3 bg-gray-200 text-gray-500 rounded-3xl focus:outline-none focus:ring-2 shadow-sm focus:border-transparent mb-5 placeholder-gray-500 mt-2;
  }

  .input-lg-disabled:focus {
    outline-color: #d3b9d6;
  }

  .input-lg-error {
    @apply w-full p-3 border border-red-700 rounded-3xl focus:outline-none focus:ring-2 shadow-sm  focus:border-transparent mb-5 placeholder-gray-500;
  }

  .input-lg-error:focus {
    outline-color: #d3b9d6;
  }

  label {
    @apply text-xs text-gray-500 font-medium capitalize ml-2;
  }

  /*** 5.0 - Headers ***/

  .title {
    @apply text-xl mb-4;
    color: #623c68;
  }

  .subtitle {
    @apply text-base text-gray-600 mb-4 -mt-2;
  }

  #notification {
    width: max-content;
  }

  /*** Body ***/

  /*** Cards ***/

  .cardless {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .profileSharedayRequest {
    height: 950px;
  }

  /*** Notifications ***/

  .notice-bg {
    background: rgba(98, 60, 104, 0.75);
  }

  .notice-h {
    height: 505px;
    border-radius: 1rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .eval-h {
    height: 550px;
    border-radius: 1rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .notice-img {
    height: 300px;
  }


  .notice-content {
    height: 210px;
  }

  .eval-content {
    height: 255px;
  }

  .lnk {
    color: #623c68;
  }

  .lnk:hover {
    color: #3f2742;
  }

  .google-map * {
    border-style: none;
  }

  #application {
    @apply text-xs w-full;
  }

  #application > tbody > tr:nth-of-type(-n + 2) > td:last-of-type {
    @apply text-right;
  }

  #initial-default {
    border-color: #3ecccb;
  }

  #select-plan-1 {
    @apply hidden;
  }

  #amount-raised-table thead {
    border-bottom: 1px solid #eee;
  }

  #amount-raised-table td {
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
  }

  #amount-raised-table td:last-of-type {
    border-right: none;
  }

  #plan-table,
  #requirements-table {
    @apply w-full;
  }

  #plan-table > thead > tr > th,
  #account-table > thead > tr > th {
    @apply font-semibold h-14;
  }

  #requirements-table > thead > tr > th {
    @apply h-14;
  }

  #requirements-table > thead > tr > th, 
  #plan-table > thead > tr > th {
    @apply text-center;
  }

  #account-table > thead > tr > th,
  #shareday-table > thead > tr > th,
  #shareday-request-table > thead > tr > th,
  #shareday-request-sent-table > thead > tr > th {
    @apply text-sm text-gray-500;
  }

  #plan-table > thead > tr > th:nth-child(1) {
    @apply w-96;
  }

  #requirements-table > tbody > tr > td {
    @apply pl-4 py-4 max-w-xs;
  }

  #requirements-table > tbody > tr,
  #plan-table > tbody > tr,
  #account-table > tbody > tr {
    @apply border-t border-gray-200 h-12;
  }

  #plan-table > tbody > tr > td,
  #shareday-table > tbody > tr > td,
  #shareday-request-sent-table > tbody > tr > td {
    @apply text-sm;
  }

  #requirements-table > tbody > tr:hover,
  #plan-table > tbody > tr:hover,
  #account-table > tbody > tr:hover {
    background: #d3b9d6;
  }

  #shareday-table > tbody > tr > td {
    @apply px-2 pb-5;
  }

  #plan-table > tbody > tr > td:not(:first-child),
  #account-table > tbody > tr > td {
    @apply text-center;
  }

  #shareday-request-sent-table > tbody > tr > td,
  #shareday-table > tbody > tr > td,
  #shareday-request-table > tbody > tr > td {
    @apply py-5 px-1;
  }
  #accreditation-table > tbody > tr {
    @apply h-20;
  }

  #review-organization > tbody > tr:nth-child(even) {
    @apply bg-gray-100;
  }

  #review-organization > tbody > tr {
    @apply h-10;
  }

  #shareday-filter {
    height: 30rem;
  }

  #shareday-details > tbody > tr {
    @apply h-12;
  }

  #shareday-details > tbody > tr > td:nth-child(2) {
    @apply pr-3;
  }

  #review-organization > tbody > tr > td:nth-child(1),
  #shareday-details > tbody > tr > td:nth-child(1) {
    @apply pl-3;
  }

  #review-organization > tbody > tr > td:nth-child(2) {
    @apply px-3;
  }

  #review-shareday-request > tbody > tr:nth-child(even),
  #shareday-details > tbody > tr:nth-child(odd) {
    background: #f6f1f6;
  }

  #review-shareday-request > tbody > tr {
    @apply h-9;
  }

  #review-shareday-request > tbody > tr:nth-of-type(1) {
    @apply w-36;
  }

  #review-shareday-request > tbody > tr > td {
    @apply py-4 px-4;
  }

  .selected-date-sm {
    @apply text-xs bg-white p-1 absolute mt-9 ml-2 rounded-xl border;
    border-color: #623c68;
  }

  .selected-date {
    @apply text-xs bg-white p-1 absolute rounded-xl border text-center;
    border-color: #623c68;
  }

  @media (max-width: 767px) {
    .selected-date,
    .selected-date-sm {
      @apply mt-7 -ml-0.5;
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    .selected-date {
      @apply mt-10 ml-1 w-12;
    }
  }

  @media (min-width: 1280px) {
    .selected-date {
      @apply mt-12 ml-6;
    }

    .selected-date-sm {
      @apply mt-10 ml-5;
    }
  }

  #hour::-webkit-scrollbar {
    width: 2px;
  }

  .modal-window::-webkit-scrollbar,
  #listings::-webkit-scrollbar,
  #shareday-modal::-webkit-scrollbar {
    width: 5px;
  }

  #hour::-webkit-scrollbar-thumb,
  .modal-window::-webkit-scrollbar-thumb,
  #listings::-webkit-scrollbar-thumb,
  #shareday-modal::-webkit-scrollbar-thumb {
    background: #623c68 rounded;
  }

  #listings::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  .default-card {
    @apply bg-white text-xs text-green-500 py-1 px-3 rounded-xl cursor-default;
  }

  .make-default {
    @apply bg-transparent text-xs text-gray-200 border-gray-500 border py-1 px-3 rounded-xl cursor-pointer;
  }

  .selected-card {
    @apply flex rounded-xl p-3 text-sm mt-5 gap-3 cursor-pointer bg-gray-100 hover:bg-gray-200;
  }

  .make-selected {
    @apply flex rounded-xl p-3 text-sm mt-5 gap-3 cursor-pointer bg-white hover:bg-gray-200;
  }

  #map,
  #map img,
  #map div {
    @apply focus:outline-none;
  }

  #map:focus,
  #map,
  #map div:focus {
    border: none !important;
  }

  .lg-map {
    height: 450px;
    border-top-right-radius: "1rem";
  }

  #modal-close {
    @apply absolute top-9 right-5 cursor-pointer;
  }

  #back-to-list {
    @apply absolute top-9 left-14 cursor-pointer;
  }

  .section-title {
    @apply text-gray-500 text-lg text-center max-w-xl w-full mx-auto mb-20;
  }

  .split-title {
    @apply text-lg mb-2 lg:text-3xl lg:mb-3;
    color: #623c68;
  }

  .col-title {
    @apply my-3 text-lg;
    color: #623c68;
  }

  #listings {
    -ms-scroll-snap-type: inline proximity;
    scroll-snap-type: inline proximity;
    scroll-behavior: smooth;
  }

  #listings > div:first-child {
    border-top: none;
  }

  .seal {
    margin: 0 !important;
  }

  .directory-input-lg {
    margin-bottom: 0;
  }

  .share-btns > div {
    width: 100%;
    margin-top: 0.5rem;
  }

  #facebook-btn:hover {
    background: #33508a !important;
  }

  #twitter-btn:hover {
    background: #1888cc !important;
  }

  #email-btn:hover {
    background: #3db7b6 !important;
  }

  .each-fade > div img,
  .event-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  #slideshow div {
    height: 100%;
  }

  .event-multiItem {
    max-height: 150px;
  }

  .event-multiItem img {
    max-height: 115px;
  }

  .tabbed-item {
    padding: 0.75rem;
    border-radius: 0.75rem;
  }

  /*** About Page ***/

  .mission-item {
    @apply text-xl font-medium p-4 rounded-xl text-center capitalize w-40 cursor-pointer;
  }

  .mission ol {
    text-align: left;
    padding-left: 20px;
  }

  .mission.lg ol {
    max-width: 450px;
    margin: 0 auto;
  }

  .mission ol li {
    margin-bottom: 10px;
  }

  .profile ol li {
    margin-bottom: 20px;
  }

  /*** Passion Map Page ***/
  .subcause-logo {
    bottom: calc(100% - 5rem);
  }

  @media (min-width: 1024px) {
    .passion-btns {
      max-width: 10rem;
    }
  }

  @media (orientation: landscape) and (max-height: 480px) {
    #shareday-modal {
      height: 20rem;
      margin: 2rem 0;
    }
  }

  @media (orientation: portrait) {
    #shareday-modal {
      height: 40rem !important;
      margin: 4rem 1rem;
    }

    .subcause-logo {
      bottom: calc(100% - 7.5rem);
    }
  }

  @media (max-width: 370px) and (orientation: portrait) {
    .subcause-logo {
      margin: 0 auto;
      display: block;
      position: relative !important;
      bottom: 0.5rem;
    }
  }

  /*** 1.0 - Body List Styles ***/

  #benefits ul li,
  .profile ul li {
    margin-bottom: 10px;
    list-style: square inside;
  }
}
